import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ProjectList from '../components/Posts/ProjectList';
import { StandardHeader } from '../components/Acf/StandardHeader';
import { PageIntro } from '../components/Acf/PageIntro';
import { ContactCtaBlock } from '../components/Acf/ContactCtaBlock';
import { decodeEntities } from '../utils/helpers';

export default class Category extends React.Component {
  render() {
    const { data, pageContext, location } = this.props
    const { wordpressPost: projectsPage = [], postsOnPage, categories, categoryFilter, site, wordpressWpSettings } = data
    const { edges: posts, totalCount } = postsOnPage
    const { title: siteTitle } = wordpressWpSettings
    const { name: category, slug } = pageContext
    const title = `${category} Category`
    const { yoast, acf } = projectsPage ? projectsPage : { title: null, yoast: {}, acf: {} }
    const {
      // Standard Header fields
      standardHeaderTitleBold,
      standardHeaderTitleRegular,
      standardHeaderSubtitleBold,
      standardHeaderSubtitleRegular,
      standardHeaderImage,
      // Page Intro fields
      pageIntroTitleBold,
      pageIntroTitleRegular,
      pageIntroContent,
      pageIntroImage,
      // Contact CTA Block fields
      contactCtaTitleBold,
      contactCtaTitleRegular,
      contactCtaContent,
      contactCtaLink,
      contactCtaImage,
    } = acf;
    return (
      <Layout className={`page-wrap`}>
        <SEO
          title={`${
            yoast.metaTitle ?
            yoast.metaTitle :
            `${decodeEntities(category)} Projects | ${decodeEntities(siteTitle)}`
          }`}
          desc={yoast.metaDescription}
          yoast={yoast}
        />
        <StandardHeader
          pageTitle={title}
          standardHeaderTitleBold={standardHeaderTitleBold}
          standardHeaderTitleRegular={standardHeaderTitleRegular}
          standardHeaderSubtitleBold={standardHeaderSubtitleBold}
          standardHeaderSubtitleRegular={standardHeaderSubtitleRegular}
          standardHeaderImage={standardHeaderImage}
          location={location}
        />
        <PageIntro
          pageIntroTitleBold={pageIntroTitleBold}
          pageIntroTitleRegular={pageIntroTitleRegular}
          pageIntroContent={pageIntroContent}
          pageIntroImage={pageIntroImage}
        />
        <ProjectList
          posts={posts}
          title={title}
          pageContext={pageContext}
          categoryFilter={categoryFilter}
          siteMetadata={wordpressWpSettings}
          pathPrefix="case-studies"
          pathSuffix={`category/${slug}/`}
        />
        <ContactCtaBlock
          contactCtaTitleBold={contactCtaTitleBold}
          contactCtaTitleRegular={contactCtaTitleRegular}
          contactCtaContent={contactCtaContent}
          contactCtaLink={contactCtaLink}
          contactCtaImage={contactCtaImage}
        />
      </Layout>
    )
  }
}

Category.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query ProjectCategoryPage($slug: String!, $limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      blogSlug
      wordpressUrl
      siteUrl
    }
    wordpressPost: wordpressPage(slug: {eq: "case-studies"}) {
      title
      wordpress_id
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
      }
      acf {
        ... StandardHeaderNonFlexibleQuery
        ... PageIntroNonFlexibleQuery
        ... ContactCtaBlockNonFlexibleQuery
      }
    }
    categoryFilter: allWordpressWpProjects {
      nodes {
        categories {
          wordpress_id
          id
          name
          slug
          id
        }
      }
    }
    postsOnPage: allWordpressWpProjects(
      filter: {
        categories: {elemMatch: {slug: {eq: $slug}}},
        slug: { ne: "gatsby-demo" }
      }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...ProjectListFields
        }
      }
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          count
        }
      }
    }
  }
`
