import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby';
import Pagination from '../Pagination';
import PostListItem from '../PostListItem'
import { PostListNavigation } from '../PostListNavigation'
import '../PostList/PostList.scss';

export default class IndexPage extends React.Component {
  render() {
    const { posts, title, pageContext, categoryFilter, pathPrefix, siteMetadata} = this.props
    return (
      <section className="post-list">
        <PostListNavigation pathPrefix={pathPrefix} categoryFilter={categoryFilter} />
        <div className="outer">
          <div className="wrap">
            <div className="inner">
              <div className="list">
                {posts.map((slide, inedx) => {
                  const projectClient = slide.node.acf && slide.node.acf.client ? slide.node.acf.client : null;
                  return (
                    <PostListItem key={inedx} data={slide.node} client={projectClient} siteMetadata={siteMetadata} pathPrefix={pathPrefix} />
                  )
                })}
              </div>
              <Pagination pageContext={pageContext} pathPrefix={`/${pathPrefix}/`} />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment ProjectListFields on wordpress__wp_projects {
    id
    categories {
      id
      name
      slug
    }
    title
    excerpt
    date(formatString: "MMMM Qo gggg")
    slug
    featured_image_url: featured_media {
      localFile {
        childImageSharp {
          fixed(width: 800) {
            ... GatsbyImageSharpFixed
          }
        }
      }
    }
    acf {
      client
    }
  }
`
